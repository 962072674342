import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Upload,
} from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  AddCommunicationPropType,
  POST,
  PUT,
  getCompany,
} from "../../utils/Communication.util";
import { fetchAllCommunication } from "../../../../redux/Communication/Communication.action";
import { OpenNotification } from "../../../common/Notification/Notification.component";
import { NotificationType } from "../../../../constants/Constants";
import {
  ErrorHandler,
  getUserData,
  searchProp,
} from "../../../../utilities/utilities";
import moment from "moment";
const AddCommunicationComponent: FC<AddCommunicationPropType> = ({
  fetchCommunications,
  communication,
  projectAction,
  projects,
  communications,
  users,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [project_id, setProjectId] = projectAction;
  const [project, setProject] = useState<any>(project_id);
  const [fileList, setFileList] = useState<any[]>([]);
  const [needs_reply, setNeedReply] = useState(false);
  const [to, setTo] = useState("");

  useEffect(() => {
    if (isModalVisible) setProject(project_id);
  }, [isModalVisible, project_id]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (communication && isModalVisible) {
      form.setFieldsValue({ ...communication });
    }
  }, [communication, isModalVisible, form]);

  const Submit = (value: any) => {
    setLoading(true);

    let formData = new FormData();
    formData.append("date", value.date.format("YYYY-MM-DD"));
    if (value.reply_date)
      formData.append("reply_date", value.reply_date.format("YYYY-MM-DD"));
    formData.append("project_id", value.project_id);
    formData.append("reference", value.reference);
    formData.append("subject", value.subject);
    if (value.response_to_id)
      formData.append("response_to_id", value.response_to_id);
    if (value.cc) formData.append("cc", value.cc.join(","));
    formData.append("from", value.from);
    formData.append("to", value.to);
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj);
    });
    if (value.remark) formData.append("remark", value.remark);
    if (communication)
      formData.append("id", communication.id?.toString() ?? "");

    (communication ? PUT(formData) : POST(formData))
      .then(() => {
        fetchCommunications({ project_id: value.project_id });
        setProjectId(value.project_id);
        form.resetFields();
        setFileList([]);
        handleOk();
        setLoading(false);
        OpenNotification(
          NotificationType.SUCCESS,
          "Communication Registered",
          ""
        );
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Register Communication",
            e.message
          )
        );
      });
  };

  return (
    <>
      {communication ? (
        <Button
          icon={<EditOutlined />}
          type="link"
          onClick={() => setIsModalVisible(true)}
        >
          Edit
        </Button>
      ) : (
        <Button
          className="btn-outline-secondary"
          style={{ float: "right" }}
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Register
        </Button>
      )}
      <Modal
        className="fixed-modal"
        centered
        title="Communication"
        visible={isModalVisible}
        onCancel={handleOk}
        footer={[
          <>
            <Button className="btn-outline" htmlType="reset" onClick={handleOk}>
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={() => form.submit()}
            >
              Save Changes
            </Button>
          </>,
        ]}
      >
        <Form
          layout="vertical"
          onFinish={Submit}
          form={form}
          initialValues={{
            date: moment(),
            project_id,
            from: getUserData().company,
            prepared_by: getUserData().full_name,
            reply_date: moment().add(3, "day"),
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                <DatePicker />
              </Form.Item>
            </div>

            <div className="col-md-12">
              <Form.Item
                name="project_id"
                label="Project"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Project"
                  {...searchProp}
                  onChange={(e) => setProject(e)}
                >
                  {projects.payload.map((e) => (
                    <Select.Option value={e.id}>{e.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                name="reference"
                label="Reference"
                rules={[{ required: false }]}
              >
                <Input placeholder="ref-no" />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item
                name="subject"
                label="Subject"
                rules={[{ required: false }]}
              >
                <Input placeholder="subject" />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item name="from" label="From">
                <Input disabled />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item name="to" label="To" rules={[{ required: false }]}>
                <AutoComplete
                  placeholder="to"
                  options={getCompany(
                    project,
                    projects.payload,
                    users.payload,
                    true
                  ).map((e, index) => ({
                    value: e,
                    key: index,
                  }))}
                  filterOption={(inputValue, option: any) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item name="cc" label="CC">
                <Select {...searchProp} mode="tags" allowClear>
                  {getCompany(project, projects.payload, users.payload, true)
                    .filter((e) => e !== to)
                    .map((e) => (
                      <Select.Option value={e}>{e}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item name="response_to_id" label="Response To">
                <Select {...searchProp}>
                  {communications.payload
                    .filter((e) => e.project_id === project)
                    .map((e) => (
                      <Select.Option value={e.id}>{`${e.reference}-${
                        e.subject
                      }  ${moment(e.date).format(
                        "DD/MM/YYYY"
                      )}`}</Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4">
                  <Form.Item label="Reply Required">
                    <Checkbox
                      checked={needs_reply}
                      onChange={(e) => setNeedReply(e.target.checked)}
                    />
                  </Form.Item>
                </div>
                {needs_reply ? (
                  <div className="col-md-8">
                    <Form.Item
                      name="reply_date"
                      label="Replay By"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="col-md-12">
              <Form.Item
                label="File"
                rules={[{ required: true, message: "Please input File" }]}
                name="file"
              >
                <Upload
                  fileList={fileList}
                  onChange={(info) => {
                    setFileList(info.fileList);
                  }}
                  name="file"
                  beforeUpload={() => {
                    return false;
                  }}
                  type="select"
                  multiple={true}
                >
                  <Button
                    className="btn-outline-secondary"
                    style={{ width: "100%" }}
                  >
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item name="remark" label="Remark">
                <Input.TextArea rows={4} />
              </Form.Item>
            </div>
            <div className="col-md-12">
              <Form.Item label="Prepared By" name="prepared_by">
                <Input disabled />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  projects: state.project.fetchAll,
  communications: state.communication.fetchAll,
  users: state.user.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchCommunications: (action: any) => dispatch(fetchAllCommunication(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCommunicationComponent);

import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  CommunicationPropType,
  DELETE,
  DELETE_STATUS,
  POST_STATUS,
} from "./utils/Communication.util";
import ReloadButtonComponent from "../common/ReloadButton/ReloadButton.component";
import { fetchAllCommunication } from "../../redux/Communication/Communication.action";
import { fetchAllProjects } from "../../redux/Project/Project.action";
import { OpenNotification } from "../common/Notification/Notification.component";
import {
  ErrorHandler,
  getUserData,
  searchProp,
  zeroPad,
} from "../../utilities/utilities";
import { NotificationType } from "../../constants/Constants";
import ShareComponent from "../common/Share/Share.component";
import AddCommunicationComponent from "./components/Add/AddCommunication.component";
import ViewCommunicationComponent from "./components/View/ViewCommunication.component";
import { Communication } from "../../redux/Communication/Communication.type";
import moment from "moment";
import ViewAttachmentComponent from "./components/View/ViewAttachment.component";
import { fetchAllUser } from "../../redux/User/User.action";
import { isNil } from "lodash";
import RemarkComponent from "./components/Remark/Remark.component";

const CommunicationComponent: FC<CommunicationPropType> = ({
  fetchCommunications,
  communications,
  fetchProjects,
  projects,
  fetchUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [project_id, setProjectId] = useState<any>();
  const [search, setSearch] = useState("");
  const [range, setRange] = useState<any>([]);
  useEffect(() => {
    fetchCommunications({ project_id });
  }, [fetchCommunications, project_id]);

  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, [fetchProjects, fetchUsers]);

  useEffect(() => {
    if (projects.payload.length > 0) setProjectId(projects.payload[0].id);
  }, [projects]);

  const onDelete = (id: any) => {
    DELETE(id)
      .then(() => {
        fetchCommunications({ project_id });

        setLoading(false);
        OpenNotification(NotificationType.SUCCESS, "Communication Removed", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Remove Communication ",
            e.message
          )
        );
      });
  };

  const onStatusRemove = (id: number) => {
    setLoading(true);
    DELETE_STATUS(id)
      .then(() => {
        setLoading(false);
        fetchCommunications({ project_id });
        OpenNotification(NotificationType.SUCCESS, "Assigned User Removed", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Remove Assigned User",
            e.message
          )
        );
      });
  };

  const getStatus = (communication: Communication) => {
    if (communication.reply_date) {
      if (communication.response_to) return <Tag color="green">Done</Tag>;
      else if (moment().isAfter(moment(communication.reply_date), "date"))
        return <Tag color="red">Outstanding</Tag>;
      else return <Tag color="yellow">Outstanding</Tag>;
    } else return <Tag color="green">Done</Tag>;
  };

  const onCreateStatus = (data: any) => {
    setLoading(true);
    POST_STATUS(data)
      .then(() => {
        setLoading(false);
        fetchCommunications({ project_id });
        OpenNotification(NotificationType.SUCCESS, "User Assigned", "");
      })
      .catch((error) => {
        setLoading(false);
        ErrorHandler(error).map((e: any) =>
          OpenNotification(
            NotificationType.ERROR,
            "Failed to Assign User",
            e.message
          )
        );
      });
  };

  return (
    <Card>
      <div className="row">
        <div className="col-md-3">
          <Form.Item label="Project">
            <Select
              {...searchProp}
              onChange={(e) => setProjectId(e)}
              value={project_id}
            >
              {projects.payload.map((e) => (
                <Select.Option value={e.id} key={e.id}>
                  {e.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-md-3">
          <Form.Item label="Search">
            <Input.Search
              placeholder="Ref-No Subject "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="col-md-4">
          <Form.Item label="Date">
            <DatePicker.RangePicker
              value={range}
              onChange={(e) => setRange(e)}
            />
          </Form.Item>
        </div>
        <div className="col-md-2">
          <ReloadButtonComponent
            onClick={() => fetchCommunications({ project_id })}
          />{" "}
          <AddCommunicationComponent
            projectAction={[project_id, setProjectId]}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12">
          <Table
            size="small"
            // scroll={{ x: 2000 }}
            tableLayout="fixed"
            loading={communications.isPending}
            dataSource={communications.payload
              ?.filter(
                (e) =>
                  (e.subject
                    ?.toLocaleLowerCase()
                    .search(search?.toLocaleLowerCase()) !== -1 ||
                    e.reference
                      ?.toLocaleLowerCase()
                      .search(search?.toLocaleLowerCase()) !== -1) &&
                  (!isNil(range)
                    ? range?.length === 0 ||
                      moment(e.date).isBetween(range[0], range[1], "D", "[]")
                    : true)
              )
              .map((e, index) => ({
                ...e,
                key: index + 1,
              }))}
            columns={[
              {
                title: "No",
                key: "no",
                dataIndex: "key",
                fixed: "left",
                width: "3%",
              },
              {
                title: "Date",
                key: "date",
                dataIndex: "date",

                width: "8%",
                defaultSortOrder: "descend",
                sorter: (a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1),
              },
              {
                title: "Reference",
                key: "reference",
                dataIndex: "reference",
              },
              {
                title: "Subject",
                key: "subject",
                dataIndex: "subject",
              },
              {
                title: "From",
                key: "from",
                dataIndex: "from",
              },
              {
                title: "To",
                key: "to",
                dataIndex: "to",
              },
              {
                title: "CC",
                key: "cc",
                dataIndex: "cc",
                render: (value, record) => record.cc,
              },
              {
                title: "Remark",
                key: "remark",
                width: "5%",
                render: (value, record) => (
                  <RemarkComponent
                    record={record}
                    fetchCommunications={() =>
                      fetchCommunications({ project_id })
                    }
                  />
                ),
              },
              {
                title: "",
                key: "attachment",
                width: "3%",
                render: (value, record) => (
                  <ViewAttachmentComponent communication={record} />
                ),
              },

              {
                title: "Status",
                key: "status",
                width: "8%",
                render: (value, record) => getStatus(record),
                filters: [
                  {
                    text: "Outstanding",
                    value: "outstanding",
                  },
                  {
                    text: "Done",
                    value: "done",
                  },
                ],
                onFilter: (value, record) => {
                  if (value === "done") {
                    return !record.reply_date ? true : false;
                  } else if (value === "outstanding") {
                    return record.reply_date && !record.response_to
                      ? true
                      : false;
                  } else return true;
                },
              },
              {
                title: "",
                key: "share",
                width: "6%",
                render: (value, record) => (
                  <ShareComponent
                    loading={loading}
                    filter={{ project_id: record.project_id }}
                    onRemove={(id: number) => onStatusRemove(id)}
                    onShare={(data: { type: string; user_id: number }) =>
                      onCreateStatus({
                        ...data,
                        communication_id: record.id,
                        identification: `Reference-${zeroPad(
                          record.reference
                        )}`,
                      })
                    }
                    payload={record.status}
                  />
                ),
              },
              {
                title: "Action",
                width: "6%",
                render: (value, record) => (
                  <Popover
                    placement="rightTop"
                    overlayClassName="action-popover"
                    trigger="focus"
                    content={
                      <div className="d-flex flex-column">
                        <ViewCommunicationComponent communication={record} />
                        {getUserData().is_super_user ? (
                          <Popconfirm
                            title="Are you sure to delete this Item?"
                            onConfirm={() => onDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              danger
                              loading={loading}
                              type="text"
                              icon={<DeleteOutlined />}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        ) : null}
                      </div>
                    }
                  >
                    <Button
                      icon={<MoreOutlined />}
                      className="btn-outline-secondary border-0"
                    ></Button>
                  </Popover>
                ),
              },
            ]}
          />
        </div>
      </div>
    </Card>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  communications: state.communication.fetchAll,
  projects: state.project.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({
  fetchCommunications: (action: any) => dispatch(fetchAllCommunication(action)),
  fetchProjects: (action: any) => dispatch(fetchAllProjects(action)),
  fetchUsers: (action: any) => dispatch(fetchAllUser(action)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationComponent);
